import queryString from 'querystring'
import type {
  GetProductsRes,
  MarketplaceProductDTO,
  OldProductDTO,
  ProductAutocompleteDTO,
  ProductLightDTO,
  ProductSearchResponseDTO,
  StoreProductFilterDTO,
  StoreSearchProductFilterDTO
} from 'ecosystem'
import { ProductType } from 'ecosystem'
import type { UrlConfig } from '../utils/fetching'
import {
  createEndpointError,
  fetchPropsFactory,
  storefrontApiCall,
  urlWithQueryParamsFactory
} from '../utils/fetching'
import { BASE_STOREFRONT_ENDPOINT } from './storeEndpoint'

const productsEndpoint = `${BASE_STOREFRONT_ENDPOINT}/products`

export type ProductQueryParams = NonNullable<any>

interface RelatedProductsQueryParams {
  ids: string
}

export async function checkoutRelatedProducts(
  config: UrlConfig<RelatedProductsQueryParams>
): Promise<ProductLightDTO[] | null> {
  const url = urlWithQueryParamsFactory<ProductQueryParams>(`${productsEndpoint}/related`, config)
  const props = fetchPropsFactory()
  const { data: products, error } = await storefrontApiCall<ProductLightDTO[]>(url, props)

  if (error) {
    return createEndpointError('related products', error)
  }

  return products
}

export async function listProducts(
  filter: Partial<StoreProductFilterDTO>,
  config?: UrlConfig<ProductQueryParams>
): Promise<GetProductsRes> {
  const url = urlWithQueryParamsFactory<ProductQueryParams>(`${productsEndpoint}/filter`, config)

  const filterWithType: Partial<StoreProductFilterDTO> = {
    types: [ProductType.REGULAR, ProductType.BUNDLE, ProductType.CONFIGURATION],
    ...filter
  }

  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(filterWithType)
    }
  })
  const { data: products, error } = await storefrontApiCall<GetProductsRes>(url, props)
  if (error || !products) {
    return createEndpointError('list products')
  }

  return products
}

export async function productById(id: string): Promise<OldProductDTO | null> {
  const url = `${productsEndpoint}/${id}`
  const props = fetchPropsFactory()
  const { data: product } = await storefrontApiCall<OldProductDTO>(url, props)
  return product
}

export async function productBySlug(slug: string): Promise<OldProductDTO | null> {
  const url = `${productsEndpoint}/slug/${slug}`
  const props = fetchPropsFactory()
  const { data: product } = await storefrontApiCall<OldProductDTO>(url, props)
  return product
}

export async function productSlugs(): Promise<string[]> {
  const url = `${productsEndpoint}/slugs`
  const props = fetchPropsFactory()
  const { data: products, error } = await storefrontApiCall<string[]>(url, props)

  if (error || !products) {
    return createEndpointError('slugs endpoint error', error)
  }

  return products
}

export async function variantsByGroupId(variantGroupId: string): Promise<OldProductDTO[] | null> {
  const props = fetchPropsFactory()
  const url = `${productsEndpoint}/variant/${variantGroupId}`
  const { data: variants, error } = await storefrontApiCall<OldProductDTO[]>(url, props)

  if (error) {
    return createEndpointError('variants by group ID', error)
  }

  return variants
}

export async function searchProducts(
  filter: StoreSearchProductFilterDTO,
  config?: UrlConfig<ProductQueryParams>
): Promise<ProductSearchResponseDTO> {
  const url = urlWithQueryParamsFactory<ProductQueryParams>(`${productsEndpoint}/search`, config)

  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(filter)
    }
  })
  const { data, error } = await storefrontApiCall<ProductSearchResponseDTO>(url, props)
  if (error || !data) {
    return createEndpointError('search products')
  }

  return data
}

export async function searchProductsAutocomplete(
  text: string,
  limit?: number
): Promise<ProductAutocompleteDTO[]> {
  const queryParams = queryString.stringify({
    text,
    limit
  })

  const url = `${productsEndpoint}/search/autocomplete?${queryParams}`
  const props = fetchPropsFactory()
  const { data, error } = await storefrontApiCall<ProductAutocompleteDTO[]>(url, props)
  if (error || !data) {
    return createEndpointError('search products autocomplete')
  }

  return data
}

export async function getMarketplaceProducts(
  slug: string
): Promise<MarketplaceProductDTO[] | null> {
  const url = `${productsEndpoint}/slug/${slug}/marketplace`
  const props = fetchPropsFactory()

  const { data, error } = await storefrontApiCall<MarketplaceProductDTO[]>(url, props)
  if (error || !data) {
    return createEndpointError('Marketplace products')
  }

  return data
}
