import React from 'react'
import { type PageProps } from 'ecosystem'
import { useLocaleCredentials } from './useLocaleCredentials'

export function withLocaleCredentials(WrappedComponent: React.FunctionComponent<any>) {
  const WithLocaleCredentialsComponent: React.FunctionComponent<any> = (
    props: PageProps<{ locale: string }>
  ) => {
    useLocaleCredentials(props.params.locale)
    return <WrappedComponent {...props} />
  }

  WithLocaleCredentialsComponent.displayName = `WithLocaleCredentials(${getDisplayName(WrappedComponent)})`

  return WithLocaleCredentialsComponent
}

function getDisplayName(WrappedComponent: React.FunctionComponent<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
