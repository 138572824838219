// *************** STOREFRONT API *******************

import {
  getDiscountCode,
  getDiscountCodeList,
  getFilterOptions,
  getFilters,
  getStore,
  validQueryParamFiltersFactory
} from './storeEndpoint'
import { login, resendMagicLink, resetPassword, setPassword, signup } from './authEndpoint'
import { findById, getCustomerOrderItems, getCustomerOrders, update } from './customerEndpoint'
import {
  checkoutRelatedProducts,
  listProducts,
  getMarketplaceProducts,
  productById,
  productBySlug,
  productSlugs,
  searchProducts,
  searchProductsAutocomplete,
  variantsByGroupId
} from './productsEndpoint'
import {
  createStorefrontOrder,
  getStorefrontOrderByOrderNumber,
  provisionOrder
} from './ordersEndpoint'
import { getWidgetByType, getWidgets, getWidgetsByView } from './widgetEndpoint'
import { getAssistantChatResponse } from './assistantEndpoint'
import {
  getAssistantV2ChatResponse,
  getAssistantV2Thread,
  textToSpeech
} from './assistantV2Endpoint'

const storefrontApi = {
  auth: {
    login,
    setPassword,
    resetPassword,
    resendMagicLink,
    signup
  },

  customer: {
    findById,
    update,
    getCustomerOrders,
    getCustomerOrderItems
  },

  store: {
    getStore,
    getFilters,
    getDiscountCode,
    getDiscountCodeList,
    getFilterOptions,
    validQueryParamFiltersFactory
  },
  products: {
    searchProducts,
    searchProductsAutocomplete,
    listProducts,
    productById,
    productBySlug,
    productSlugs,
    variantsByGroupId,
    checkoutRelatedProducts,
    getMarketplaceProducts
  },
  orders: {
    createStorefrontOrder,
    getStorefrontOrder: getStorefrontOrderByOrderNumber,
    provisionOrder
  },
  widgets: {
    getWidgetByType,
    getWidgetsByView,
    getWidgets
  },
  assistant: {
    getAssistantChatResponse
  },
  assistantV2: {
    getAssistantV2Thread,
    getAssistantV2ChatResponse,
    textToSpeech
  }
}

export default storefrontApi
