import { createMinorUnit, DEFAULT_COUNTRY, getTax, getVatRateByCountryCode } from 'shared-utils'
import {
  type CreateOrderShippingConfig,
  type Language,
  type MinorUnit,
  type ShippingOption
} from 'ecosystem'
import { EXTRA_FEE, MINIMUM_ORDER_AMOUNT } from '../constants'

/**
 * Use paymentShippingFactory if there is no third party shipping providers (like Ingrid)
 */
export const paymentShippingFactory = ({
  orderAmount,
  locale,
  countryCode = DEFAULT_COUNTRY,
  opts
}: {
  orderAmount: MinorUnit
  locale: Language
  countryCode?: string
  opts?: CreateOrderShippingConfig['simpleShipping']
}): ShippingOption => {
  const {
    minimumOrderAmountForFreeDelivery = MINIMUM_ORDER_AMOUNT,
    deliveryFee = EXTRA_FEE,
    labels
  } = opts || {}
  const taxRate = createMinorUnit(getVatRateByCountryCode(countryCode))
  const isFree = orderAmount >= createMinorUnit(minimumOrderAmountForFreeDelivery)
  const quantity = 1
  const unitPrice = !isFree ? createMinorUnit(deliveryFee) : 0
  const totalDiscountAmount = 0
  const totalAmount = quantity * unitPrice - totalDiscountAmount
  const totalTaxAmount = getTax(totalAmount, taxRate, true)

  const localeDictionary = {
    en: {
      freeDeliveryLabel: 'Free - Delivery with telephone notification',
      paidDeliveryLabel: 'Shipping - Delivery with telephone notification'
    },
    sv: {
      freeDeliveryLabel: 'Gratis - Leverans med telefonavisering',
      paidDeliveryLabel: 'Frakt - Leverans med telefonavisering'
    },
    no: {
      freeDeliveryLabel: 'Gratis - Levering med telefonvarsel',
      paidDeliveryLabel: 'Frakt - Levering med telefonvarsel'
    },
    da: {
      freeDeliveryLabel: 'Gratis - Levering med telefonisk advisering',
      paidDeliveryLabel: 'Fragt - Levering med telefonisk advisering'
    }
  }

  const serviceLabel = labels ?? localeDictionary[locale]

  return {
    id: 'delivery',
    name: isFree ? serviceLabel.freeDeliveryLabel : serviceLabel.paidDeliveryLabel,
    price: unitPrice,
    tax_amount: totalTaxAmount,
    tax_rate: taxRate
  }
}
