import type {
  CreateOrderItemDTO,
  CreateUpdateOrderDTO,
  DiscountDTO,
  Option,
  OrderDTO,
  OrderShippingDTO,
  PaymentProviderName
} from 'ecosystem'
import { OrderStatus } from 'ecosystem'
import { createEndpointError, fetchPropsFactory, storefrontApiCall } from '../../utils/fetching'
import { ORDERS_ENDPOINT } from './constants'

interface CreateStorefrontOrderParams {
  storeId: string
  backendOrderLines: CreateOrderItemDTO[]
  cartDiscountCode: Option<DiscountDTO>
  shipping?: OrderShippingDTO
  paymentProviderInfo: {
    providerName: PaymentProviderName
  }
}

export async function createStorefrontOrder({
  storeId,
  backendOrderLines,
  cartDiscountCode,
  shipping,
  paymentProviderInfo
}: CreateStorefrontOrderParams): Promise<OrderDTO> {
  const payload: CreateUpdateOrderDTO = {
    status: OrderStatus.CREATED,
    storeId,
    items: backendOrderLines,
    customer: null,
    paymentProvider: paymentProviderInfo.providerName,
    ...(shipping
      ? {
          shippingProvider: shipping.provider,
          shippingId: shipping.providerSessionId
        }
      : {}),
    ...(cartDiscountCode?.code ? { discountCode: cartDiscountCode?.code } : {})
  }

  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(payload)
    }
  })

  const { data: order, error } = await storefrontApiCall<OrderDTO>(ORDERS_ENDPOINT, props)
  if (error || !order) {
    return createEndpointError('create storefront order')
  }

  return order
}
