import type { OrderDTO } from 'ecosystem'
import {
  fetchPropsFactory,
  storefrontApiCall,
  urlWithQueryParamsFactory
} from '../../utils/fetching'
import { ORDERS_ENDPOINT } from './constants'

export interface GetStorefrontOrderParams {
  paymentId?: string
  orderNumber?: string
}

export async function getStorefrontOrderByOrderNumber(params: GetStorefrontOrderParams) {
  const url = urlWithQueryParamsFactory<GetStorefrontOrderParams>(`${ORDERS_ENDPOINT}/`, {
    queryParams: params
  })
  const props = fetchPropsFactory({
    props: {
      method: 'GET',
      credentials: 'include'
    }
  })

  return storefrontApiCall<OrderDTO>(url, props)
}
