import {
  type CreateUpdateOrderDTO,
  CustomerType,
  type NexiPhoneNumber,
  type NexiRetrievePaymentResponse,
  type OrderDTO,
  type PaymentProviderNexiOptions
} from 'ecosystem'
import { convertMinorUnitToValue, getTax, getVatRateByCountryCode, roundNumber } from 'shared-utils'
import { createError } from '../../../../utils/fetching'
import { type CompleteStorefrontOrderProps } from './types'
import { generateBasePayload, generatePayloadFromIngrid } from './utils'

const generatePhoneNumber = (phoneNumber?: NexiPhoneNumber) => {
  return `${phoneNumber?.prefix ?? ''}${phoneNumber?.number ?? ''}`
}

const generateBasePayloadFromNexi = (
  oldOrder: OrderDTO,
  nexiResponse: NexiRetrievePaymentResponse,
  nexiOptions?: PaymentProviderNexiOptions
): CreateUpdateOrderDTO => {
  const { payment } = nexiResponse
  const { billingAddress, privatePerson, shippingAddress } = payment.consumer ?? {}
  const nexiItems = payment.charges?.[0].orderItems ?? []

  const { firstName = '', lastName = '', email = '' } = privatePerson ?? {}

  const mobilePhone = generatePhoneNumber(privatePerson?.phoneNumber)
  const fullName = `${firstName} ${lastName}`

  const orderDiscount = nexiItems.reduce((sum, item) => {
    return item.reference === 'discount' ? sum + Math.abs(item.grossTotalAmount) : sum
  }, 0)

  const shippingCost = nexiItems.reduce((sum, item) => {
    return item.reference === 'delivery' ? sum + item.grossTotalAmount : sum
  }, 0)

  const orderAmount = convertMinorUnitToValue(payment.orderDetails.amount)

  return {
    ...generateBasePayload(oldOrder),
    billingAddress: {
      address1: billingAddress?.addressLine1 ?? '',
      address2: '',
      country: billingAddress?.country ?? '',
      mobilePhone: generatePhoneNumber(billingAddress?.phoneNumber) || mobilePhone,
      postalCode: billingAddress?.postalCode ?? '',
      postalRegion: billingAddress?.city ?? '',
      attention: billingAddress?.receiverLine ?? fullName,
      reference: ''
    },
    shippingAddress: {
      address1: shippingAddress?.addressLine1 ?? '',
      address2: '',
      country: shippingAddress?.country ?? '',
      mobilePhone: generatePhoneNumber(shippingAddress?.phoneNumber) || mobilePhone,
      postalCode: shippingAddress?.postalCode ?? '',
      postalRegion: shippingAddress?.city ?? '',
      attention: shippingAddress?.receiverLine ?? fullName,
      reference: ''
    },
    currency: payment.orderDetails.currency,
    customer: {
      email,
      firstName,
      lastName,
      type: CustomerType.CONSUMER
    },
    orderAmount,
    orderTaxAmount: roundNumber(
      getTax(orderAmount, getVatRateByCountryCode(nexiOptions?.countryCode))
    ),
    orderDiscount: roundNumber(convertMinorUnitToValue(orderDiscount)),
    shippingCost: convertMinorUnitToValue(shippingCost),
    paymentId: payment.paymentId,
    paymentProvider: 'NEXI'
  }
}

export const nexiPaymentProvider = ({
  order: oldOrder,
  paymentProviderInfo,
  shippingProviderInfo
}: CompleteStorefrontOrderProps): CreateUpdateOrderDTO => {
  if (!paymentProviderInfo.providerResponse?.nexiResponse) {
    throw createError('Not found NEXI order')
  }
  const shippingProviderName = shippingProviderInfo?.providerName ?? 'DEFAULT'

  let payload = generateBasePayloadFromNexi(
    oldOrder,
    paymentProviderInfo.providerResponse?.nexiResponse,
    paymentProviderInfo.providerOptions?.nexi
  )

  switch (shippingProviderName) {
    case 'DEFAULT':
      break
    case 'INGRID':
      payload = generatePayloadFromIngrid(payload, shippingProviderInfo?.ingridResponse)
  }

  return payload
}
