import type {
  JoinedWidgetType,
  RelatedWidget,
  StorefrontWidget,
  DynamicWidgetType
} from 'ecosystem'
import storefrontApi from '../storefront-api'
import type { GetWidgetsFilter, EndpointWidgetParams } from '../storefront-api/widgetEndpoint'

export const sortByOrder = (a: StorefrontWidget<any>, b: StorefrontWidget<any>) =>
  a.widget.order - b.widget.order

export const relatedIntoShared = async (
  widgets: StorefrontWidget<any>[]
): Promise<StorefrontWidget<any>[]> => {
  const updatedWidgets = [...widgets]

  const relatedWidgetsIds: string[] = Object.keys(
    updatedWidgets
      .filter((w) => w.type === 'JOINED_WIDGET')
      .reduce<Record<string, boolean>>((ids, { widget }) => {
        const { relatedSharedWidgets = [] } = widget as JoinedWidgetType<RelatedWidget>

        relatedSharedWidgets.forEach((relatedItem) => {
          ids[relatedItem.id] = true
        })

        return ids
      }, {})
  )

  if (relatedWidgetsIds.length) {
    const sharedWidgets = await storefrontApi.widgets
      .getWidgets({
        view: 'SHARED_WIDGETS',
        filter: {
          key: 'id',
          textValues: relatedWidgetsIds
        } as GetWidgetsFilter
      })
      .then((resSharedWidgets) => {
        return resSharedWidgets.reduce<Record<string, StorefrontWidget<any>>>((res, widget) => {
          // @ts-expect-error -- Not cast from unknown object
          res[widget.widget.id] = widget

          return res
        }, {})
      })

    updatedWidgets.forEach((w) => {
      if (w.type !== 'JOINED_WIDGET') {
        return
      }
      const widget = w.widget as JoinedWidgetType<RelatedWidget>
      w.widget.relatedSharedWidgets = widget.relatedSharedWidgets.map(
        (relatedWidget) => sharedWidgets[relatedWidget.id]
      )
    })
  }

  return updatedWidgets
}

export const pageWidgets = async (viewId: string) => {
  return storefrontApi.widgets.getWidgetsByView(viewId).then(relatedIntoShared)
}

export const dynamicPageWidgets = async (
  params: EndpointWidgetParams,
  viewId: DynamicWidgetType
) => {
  return storefrontApi.widgets
    .getWidgetByType(params)
    .then((w) => (w.length ? w : storefrontApi.widgets.getWidgetsByView(viewId)))
    .then(relatedIntoShared)
}
