import type { DiscountDTO, StorefrontOrderLine } from 'ecosystem'
import { createMinorUnit } from 'shared-utils'

export const orderLineValueCalculator = (
  orderLine: StorefrontOrderLine,
  cartDiscountCode: DiscountDTO | null
) => {
  const isDiscountCandidate =
    (orderLine.product.brand?.id &&
      cartDiscountCode?.brandIds?.includes(orderLine.product.brand.id)) ||
    cartDiscountCode?.categoryIds?.some((c) => orderLine.product.categoryIds?.includes(c)) ||
    cartDiscountCode?.tagIds?.some((t) =>
      orderLine.product.tags?.map((ptag) => ptag.id).includes(t)
    )

  const isOriginalPriceLower = orderLine.unitPrice > orderLine.originalPrice

  const quantity = orderLine.quantity
  let unitPrice = createMinorUnit(orderLine.unitPrice)
  let unitPriceWithDiscount = unitPrice
  let totalDiscountAmount = 0

  if (cartDiscountCode && isDiscountCandidate) {
    const discountRate = cartDiscountCode.value / 100

    if (!cartDiscountCode.campaign && !isOriginalPriceLower) {
      unitPrice = createMinorUnit(orderLine.originalPrice)
    }

    totalDiscountAmount = unitPrice * orderLine.quantity * discountRate
    unitPriceWithDiscount = unitPrice - unitPrice * discountRate
  }

  return {
    quantity,
    unitPrice,
    unitPriceWithDiscount,
    totalDiscountAmount,
    totalAmount: quantity * unitPrice - totalDiscountAmount
  }
}
