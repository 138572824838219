import credentials from './credentials'

interface LocaleCredentials {
  apiKey: string | undefined
  secret: string | undefined
}

const LOCALE_CREDENTIAL_MAP: Record<string, LocaleCredentials> = {
  en: {
    apiKey: process.env.API_KEY_EN,
    secret: process.env.SECRET_KEY_EN
  },
  no: {
    apiKey: process.env.API_KEY_NO,
    secret: process.env.SECRET_KEY_NO
  },
  da: {
    apiKey: process.env.API_KEY_DA,
    secret: process.env.SECRET_KEY_DA
  },
  sv: {
    apiKey: process.env.API_KEY,
    secret: process.env.SECRET_KEY
  }
}

export function useLocaleCredentials(locale: string) {
  if (!locale) {
    throw new Error(
      'No locale found for this component. This HOC works in pages and layout components'
    )
  }

  const { apiKey, secret } = LOCALE_CREDENTIAL_MAP[locale] || LOCALE_CREDENTIAL_MAP.sv

  if (!apiKey || !secret) {
    throw new Error(`No api or secret detected for ${locale}`)
  }

  credentials.apiKey = apiKey
  credentials.secret = secret
}
