import { type Option, type OrderDTO, type PaymentProviderConfig } from 'ecosystem'
import { provisionOrderKlarnaProvider } from './provisionOrderKlarnaProvider'
import { provisionOrderNexiProvider } from './provisionOrderNexiProvider'
import { type OrderProvisionResult } from './types'
import { provisionOrderSveaProvider } from './provisionOrderSveaProvider'

export const provisionOrder = async (
  orderId: string,
  paymentProviderConfig?: PaymentProviderConfig
): Promise<Option<OrderProvisionResult>> => {
  const paymentProviderName = paymentProviderConfig?.providerName ?? 'KLARNA'
  let storefrontOrder: Option<OrderDTO> = null
  let shippingProviderResult: OrderProvisionResult['shippingProviderResult']

  const paymentProviderResult: OrderProvisionResult['paymentProviderResult'] = {
    providerName: paymentProviderName
  }

  switch (paymentProviderName) {
    case 'KLARNA':
      {
        const provisionOrderKlarnaProviderResult = await provisionOrderKlarnaProvider(
          orderId,
          paymentProviderConfig
        )
        storefrontOrder = provisionOrderKlarnaProviderResult?.storefrontOrder ?? null
        paymentProviderResult.klarnaResponse = provisionOrderKlarnaProviderResult?.klarnaOrder
        shippingProviderResult = provisionOrderKlarnaProviderResult?.shippingProviderResult
      }
      break

    case 'NEXI':
      {
        const provisionOrderNexiProviderResult = await provisionOrderNexiProvider(
          orderId,
          paymentProviderConfig
        )
        storefrontOrder = provisionOrderNexiProviderResult?.storefrontOrder ?? null
        paymentProviderResult.nexiResponse = provisionOrderNexiProviderResult?.nexiResponse
        shippingProviderResult = provisionOrderNexiProviderResult?.shippingProviderResult
      }
      break

    case 'SVEA':
      {
        const provisionOrderSveaProviderResult = await provisionOrderSveaProvider(
          orderId,
          paymentProviderConfig
        )
        storefrontOrder = provisionOrderSveaProviderResult?.storefrontOrder ?? null
        paymentProviderResult.sveaResponse = provisionOrderSveaProviderResult?.sveaResponse
        shippingProviderResult = provisionOrderSveaProviderResult?.shippingProviderResult
      }
      break
    case 'LEDYER':
      // todo: add LEDYER
      break
  }

  return {
    storefrontOrder,
    paymentProviderResult,
    ...(shippingProviderResult && {
      shippingProviderResult
    })
  }
}
