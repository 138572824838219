import crypto from 'crypto'
import { SVEA_MERCHANT_ID, SVEA_SECRET_KEY } from './constants'

/** @see https://paymentsdocs.svea.com/docs/getting-started/authentication */
export const createAuthenticationToken = (message = '') => {
  const timestamp = new Date().toISOString().replace('T', ' ').split('.')[0] // ISO Format

  const hash = crypto
    .createHash('sha512')
    .update(message + SVEA_SECRET_KEY + timestamp, 'utf-8')
    .digest('hex')
    .toUpperCase()

  const token = Buffer.from(`${SVEA_MERCHANT_ID}:${hash}`).toString('base64')

  return { token, timestamp }
}

export const createSveaHeaders = (requestBody?: string) => {
  const { timestamp, token } = createAuthenticationToken(requestBody)

  return {
    Authorization: `Svea ${token}`,
    Timestamp: timestamp
  }
}
