'use client'
import { useCallback, useState } from 'react'

export interface UseGenericRequestFetchResult<T> {
  unwrap: () => Promise<T>
}

const useGenericRequest = <T>() => {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<{ message: string } | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchNextRoute = useCallback((url: string, props?: RequestInit) => {
    setData(null)
    setError(null)
    setIsLoading(true)

    let result: { status: 'PENDING' | 'SUCCESS' | 'ERROR'; data?: any; error?: any } = {
      status: 'PENDING'
    }

    const resPromise = fetch(url, props)
      .then((res) =>
        res
          .json()
          .then((responseData) => {
            if (responseData?.error) {
              throw new Error(responseData.error)
            }
            setData(responseData)
            setError(null)
            result = {
              status: 'SUCCESS',
              data: responseData
            }
          })
          .catch((err) => {
            setError(err)
            setData(null)

            result = {
              status: 'ERROR',
              error: err
            }
          })
      )
      .catch((err) => {
        setError(err)
        setData(null)

        result = {
          status: 'ERROR',
          error: err
        }
      })
      .finally(() => {
        setIsLoading(false)
      })

    return {
      unwrap: () =>
        new Promise<T>((resolve, reject) => {
          resPromise.then(() => {
            if (result.status === 'SUCCESS') {
              resolve(result.data)
            } else if (result.status === 'ERROR') {
              // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors -- need to return error
              reject(result.error)
            }
          })
        })
    }
  }, [])

  const reset = useCallback(() => {
    setData(null)
    setError(null)
    setIsLoading(false)
  }, [])

  return { fetchNextRoute, data, error, isLoading, reset }
}

export default useGenericRequest
