import type { Option, PaymentProviderConfig } from 'ecosystem'

export const getCountryCodeFromPaymentConfig = (paymentConfig?: Option<PaymentProviderConfig>) => {
  if (!paymentConfig) {
    return
  }

  switch (paymentConfig.providerName) {
    case 'KLARNA':
      return paymentConfig.providerOptions?.klarna?.purchaseCountry
    case 'NEXI':
      return paymentConfig.providerOptions?.nexi?.countryCode
    case 'SVEA':
      return paymentConfig.providerOptions?.svea?.countryCode
    case 'LEDYER':
      return paymentConfig.providerOptions?.ledyer?.countryCode
  }
}
