'use client'
import useSWR from 'swr'

// @ts-expect-error -- Needs work on the types to remove this warning.
const fetcher = (...args: any[]) => fetch(...args).then((res) => res.json())

export interface DataResponse<T> {
  data: T | undefined
  isLoading: boolean
  error: any
}

type DataPayload<T> = Record<string, T> | null

function useGetCachedRequest<T, Key = string>(
  url: string | null,
  key?: Key
): DataResponse<T | null> {
  type Payload = Key extends undefined ? T : DataPayload<T>
  const { data: payload, error } = useSWR<Payload | { error: string }>(url, fetcher)

  // @ts-expect-error -- FIX
  if (payload && 'error' in payload) {
    return {
      data: null,
      isLoading: false,
      error: payload.error
    }
  }

  let data: DataResponse<T>['data']

  if (typeof key !== 'undefined') {
    data = payload ? (payload as DataPayload<T>)?.[key as unknown as string] : undefined
  } else {
    data = payload as T
  }

  return {
    data,
    isLoading: !url ? false : !data && !error,
    error
  }
}

export default useGetCachedRequest
