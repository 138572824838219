import {
  type CreateUpdateOrderDTO,
  Currency,
  type IngridSession,
  type OrderDTO,
  OrderStatus
} from 'ecosystem'
import { convertMinorUnitToValue } from 'shared-utils'
import { transformOrderItemsToCreateOrderItems } from '../../../../utils/orders'

export const generateBasePayload = (oldOrder: OrderDTO): CreateUpdateOrderDTO => {
  return {
    ...oldOrder,
    items: oldOrder.items ? transformOrderItemsToCreateOrderItems(oldOrder.items) : [],
    status: OrderStatus.PAID,
    currency: Currency.SEK
  }
}

export const generatePayloadFromIngrid = (
  basePayload: CreateUpdateOrderDTO,
  ingridResponse?: IngridSession
) => {
  if (!ingridResponse) {
    return basePayload
  }

  /* eslint-disable camelcase -- need */
  const { delivery_groups } = ingridResponse
  const deliveryGroup = delivery_groups[0]
  const deliveryAddress = deliveryGroup.addresses.delivery_address
  const shipping = deliveryGroup.shipping

  return {
    ...basePayload,
    shippingCost: convertMinorUnitToValue(deliveryGroup.pricing.price),
    ...(deliveryAddress
      ? {
          shippingAddress: {
            address1: deliveryAddress.address_lines?.[0] || '',
            address2: '',
            country: deliveryAddress.country || '',
            mobilePhone: deliveryAddress.phone || '',
            postalCode: deliveryAddress.postal_code || '',
            postalRegion: deliveryAddress.city || '',
            attention: `${deliveryAddress.first_name} ${deliveryAddress.last_name}`,
            reference: `${shipping.carrier} ${shipping.delivery_type}, ${shipping.product}`
          }
        }
      : {})
  }
}
