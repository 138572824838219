import {
  type NexiApiCallResponse,
  type NexiCreatePaymentRequest,
  type NexiCreatePaymentResponse,
  type NexiRetrievePaymentResponse,
  type NexiUpdateOrderRequest,
  type NexiUpdateReferenceInformationRequest
} from 'ecosystem'
import type { ApiCallResponse } from '../../fetching'
import { apiCall } from '../../fetching'
import { NEXI_API_URL, NEXI_AUTH_HEADERS } from '../constants'

const CHECKOUT_URL = `${NEXI_API_URL}/v1/payments`

const nexiEndpointDictionary = {
  create: {
    method: 'POST',
    url: CHECKOUT_URL
  },

  retrieve: {
    method: 'GET',
    url: (paymentId: string) => `${CHECKOUT_URL}/${paymentId}`
  },

  updateReferenceInformation: {
    method: 'PUT',
    url: (paymentId: string) => `${CHECKOUT_URL}/${paymentId}/referenceinformation`
  },

  updateOrder: {
    method: 'PUT',
    url: (paymentId: string) => `${CHECKOUT_URL}/${paymentId}/orderitems`
  }
} as const

const headersJson = {
  'Content-Type': 'application/json'
}

const nexiApiCall = async <Data>(
  url: string,
  { headers, ...props }: RequestInit
): Promise<ApiCallResponse<Data>> => {
  if (url.includes('undefined')) {
    throw new Error('Nexi environment variables missing')
  }
  const { error, data } = await apiCall<NexiApiCallResponse<Data>>(url, {
    headers: {
      ...NEXI_AUTH_HEADERS,
      ...headers
    },
    ...props
  })

  if (error || !data) {
    return {
      data: null,
      error
    }
  }

  // @ts-expect-error -- FIX
  if ('errors' in data) {
    return {
      data: null,
      error:
        typeof data.errors === 'object'
          ? Object.values(data.errors).flat().join('; ')
          : 'Something wrong!'
    }
  }

  // @ts-expect-error -- FIX
  if ('message' in data && 'code' in data && 'source' in data) {
    return {
      data: null,
      error: data.message
    }
  }

  return {
    data,
    error: null
  }
}

/**
 * Initializes a new payment object that becomes the object used throughout the checkout flow for a particular
 * customer and order.
 */
export const createPayment = (payload: NexiCreatePaymentRequest) =>
  nexiApiCall<NexiCreatePaymentResponse>(nexiEndpointDictionary.create.url, {
    method: nexiEndpointDictionary.create.method,
    headers: headersJson,
    body: JSON.stringify(payload)
  })

/**
 * Retrieves the details of an existing payment.
 */
export const retrievePayment = (paymentId: string) =>
  nexiApiCall<NexiRetrievePaymentResponse>(nexiEndpointDictionary.retrieve.url(paymentId), {
    method: nexiEndpointDictionary.retrieve.method
  })

/**
 * Updates the specified payment object with a new reference string and a checkoutUrl.
 *
 * If you instead want to update the order of a payment object, use the Update order method.
 */
export const updateReferenceInformation = (
  paymentId: string,
  payload: NexiUpdateReferenceInformationRequest
) =>
  nexiApiCall(nexiEndpointDictionary.updateReferenceInformation.url(paymentId), {
    method: nexiEndpointDictionary.updateReferenceInformation.method,
    headers: headersJson,
    body: JSON.stringify(payload)
  })

/**
 * Updates the order for the specified payment. This endpoint makes it possible to change the order on the checkout
 * page after the payment object has been created.
 * This is typically used when managing destination-based shipping costs at the checkout.
 */
export const updateOrder = (paymentId: string, payload: NexiUpdateOrderRequest) =>
  nexiApiCall(nexiEndpointDictionary.updateOrder.url(paymentId), {
    method: nexiEndpointDictionary.updateOrder.method,
    headers: headersJson,
    body: JSON.stringify(payload)
  })
