import {
  createPayment,
  retrievePayment,
  updateOrder,
  updateReferenceInformation
} from './nexiCheckoutEndpoints'

export const nexiCheckoutApi = {
  createPayment,
  retrievePayment,
  updateReferenceInformation,
  updateOrder
}
