class Credentials {
  private static instance: Credentials
  private _apiKey!: string
  private _secret!: string

  private constructor() {
    this.initializeApiKeyAndSecret()
  }

  public static getInstance(): Credentials {
    if (!Credentials.instance) {
      Credentials.instance = new Credentials()
    }
    return Credentials.instance
  }

  private initializeApiKeyAndSecret(): void {
    const apiKey = process.env.API_KEY
    const secret = process.env.SECRET_KEY

    if (!apiKey || !secret) {
      throw new Error('No api or secret detected')
    }

    this._apiKey = apiKey
    this._secret = secret
  }

  public get apiKey(): string {
    return this._apiKey
  }

  public set apiKey(value: string) {
    this._apiKey = value
  }

  public get secret(): string {
    return this._secret
  }

  public set secret(value: string) {
    this._secret = value
  }

  public reset(): void {
    this.initializeApiKeyAndSecret()
  }
}

export default Credentials.getInstance()
