import type {
  CreateCustomerDTO,
  CredentialsDTO,
  CustomerAuthUpdateDTO,
  CustomerLightDTO
} from 'ecosystem'
import { createError, fetchPropsFactory, storefrontApiCall } from '../utils/fetching'
import { BASE_STOREFRONT_ENDPOINT } from './storeEndpoint'

const authEndpoint = `${BASE_STOREFRONT_ENDPOINT}/auth`

export async function login(credentials: CredentialsDTO): Promise<CustomerLightDTO> {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(credentials)
    }
  })
  const url = `${authEndpoint}/login`
  const { data: customer, error } = await storefrontApiCall<CustomerLightDTO>(url, props)

  if (!customer) {
    throw createError(error)
  }

  return customer
}

export async function setPassword(params: CustomerAuthUpdateDTO): Promise<CustomerLightDTO> {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(params)
    }
  })
  const url = `${authEndpoint}/set-password`
  const { data: customer, error } = await storefrontApiCall<CustomerLightDTO>(url, props)

  if (!customer) {
    throw createError(error)
  }

  return customer
}

export async function resetPassword(params: CustomerAuthUpdateDTO): Promise<void> {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(params)
    }
  })
  const url = `${authEndpoint}/reset-password`
  const { error } = await storefrontApiCall<null>(url, props)

  if (error) {
    throw createError(error)
  }
}

export async function resendMagicLink(params: CustomerAuthUpdateDTO): Promise<void> {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(params)
    }
  })
  const url = `${authEndpoint}/resend-magic-link`
  const { error } = await storefrontApiCall<null>(url, props)

  if (error) {
    throw createError(error)
  }
}

export async function signup(params: CreateCustomerDTO): Promise<CustomerLightDTO> {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(params)
    }
  })
  const url = `${authEndpoint}/signup`
  const { data: customer, error } = await storefrontApiCall<CustomerLightDTO>(url, props)

  if (!customer) {
    throw createError(error)
  }

  return customer
}
