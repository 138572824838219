import type { StorefrontWidget, StorefrontWidgetTypeAll } from 'ecosystem'
import { createEndpointError, fetchPropsFactory, storefrontApiCall } from '../utils/fetching'
import { BASE_STOREFRONT_ENDPOINT } from './storeEndpoint'

export type EndpointWidgetType = 'category' | 'brand' | 'tag'
export interface EndpointWidgetParams {
  id: string
  type: EndpointWidgetType
}

export interface GetWidgetsFilter {
  key: string
  textValues?: string[]
  numberValues?: number[]
}

interface GetWidgetsPayload {
  type?: StorefrontWidgetTypeAll
  view: string
  filter?: GetWidgetsFilter | Record<string, never>
}

const widgetsEndpoint = `${BASE_STOREFRONT_ENDPOINT}/store/widgets`

function widgetEndpointFactory({ id, type }: EndpointWidgetParams) {
  return `${widgetsEndpoint}/${type}/${id}`
}

function getWidgetEndpointFactory({ type, view }: Omit<GetWidgetsPayload, 'filter'>) {
  return `${widgetsEndpoint}/filter?${type ? `type=${type}&` : ''}view=${view}`
}

export async function getWidgetByType({ id, type }: EndpointWidgetParams) {
  const url = widgetEndpointFactory({ id, type })
  const props = fetchPropsFactory({
    props: {
      method: 'GET',
      credentials: 'include'
    }
  })

  const { data: widgets, error } = await storefrontApiCall<StorefrontWidget<any>[]>(url, props)

  if (error || !widgets) {
    return createEndpointError('widgets endpoint')
  }

  return widgets
}

export async function getWidgetsByView(viewName: string) {
  const url = `${widgetsEndpoint}/view/${viewName}`
  const props = fetchPropsFactory({
    props: {
      method: 'GET',
      credentials: 'include'
    }
  })

  const { data: widgets, error } = await storefrontApiCall<StorefrontWidget<unknown>[]>(url, props)

  if (error || !widgets) {
    return createEndpointError('view widgets endpoint')
  }

  return widgets
}

export async function getWidgets({ filter = {}, ...restOfPayload }: GetWidgetsPayload) {
  const url = getWidgetEndpointFactory(restOfPayload)
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(filter)
    }
  })

  const { data: widgets, error } = await storefrontApiCall<StorefrontWidget<unknown>[]>(url, props)

  if (error || !widgets) {
    return createEndpointError('store front admin widgets endpoint')
  }

  return widgets
}
