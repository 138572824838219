import type {
  IngridApiCallResponse,
  IngridCompleteSessionRequest,
  IngridCompleteSessionResponse,
  IngridCreateSessionRequest,
  IngridCreateSessionResponse,
  IngridGetSessionResponse,
  IngridUpdateSessionRequest,
  IngridUpdateSessionResponse
} from 'ecosystem'
import type { ApiCallResponse } from '../../fetching'
import { apiCall } from '../../fetching'
import { INGRID_AUTH_HEADERS, INGRID_API_URL } from '../constants'

const CHECKOUT_URL = `${INGRID_API_URL}/v1/delivery_checkout`

const ingridEndpointDictionary = {
  create: {
    method: 'POST',
    url: `${CHECKOUT_URL}/session.create`
  },

  update: {
    method: 'POST',
    url: `${CHECKOUT_URL}/session.update`
  },

  get: {
    method: 'GET',
    url: (sessionId: string) => `${CHECKOUT_URL}/session.get?checkout_session_id=${sessionId}`
  },

  pull: {
    method: 'GET',
    url: (sessionId: string) => `${CHECKOUT_URL}/session.pull?checkout_session_id=${sessionId}`
  },

  complete: {
    method: 'POST',
    url: `${CHECKOUT_URL}/session.complete`
  }
} as const

const headersJson = {
  'Content-Type': 'application/json'
}

const ingridApiCall = async <Data>(
  url: string,
  { headers, ...props }: RequestInit
): Promise<ApiCallResponse<Data>> => {
  if (url.includes('undefined')) {
    throw new Error('Ingrid environment variables missing')
  }
  const { error, data } = await apiCall<IngridApiCallResponse<Data>>(url, {
    headers: {
      ...INGRID_AUTH_HEADERS,
      ...headers
    },
    ...props
  })

  if (error || !data) {
    return {
      data: null,
      error
    }
  }

  // @ts-expect-error -- FIX
  if ('error' in data) {
    return {
      data: null,
      error: data.error
    }
  }

  return {
    data,
    error: null
  }
}

/**
 * When the customer is ready to finalize the purchase, you need to create a Checkout Session from your backend.
 * The simplest possible request is presented below. Newly created session is in an ACTIVE state.
 */
export const createSession = (payload: IngridCreateSessionRequest) =>
  ingridApiCall<IngridCreateSessionResponse>(ingridEndpointDictionary.create.url, {
    method: ingridEndpointDictionary.create.method,
    headers: headersJson,
    body: JSON.stringify(payload)
  })
/**
 * The session.update method can be used to update the cart contents or to apply shipping date changes
 * or split to the cart items among other things.
 * You can update either cart, apply shipping date, split cart items or everything in one request.
 */
export const updateSession = (payload: IngridUpdateSessionRequest) =>
  ingridApiCall<IngridUpdateSessionResponse>(ingridEndpointDictionary.update.url, {
    method: ingridEndpointDictionary.update.method,
    headers: headersJson,
    body: JSON.stringify(payload)
  })

/**
 * The session.complete method is used when the checkout is done.
 * The state of the session is changed from ACTIVE to COMPLETE.
 * At this point, if the customer has not made a choice we will generate one for the customer based on
 * the information in the customer field provided in the request.
 * After this action the session will be "frozen" and could not be modified.
 *
 * If you are using Address Form the customer field is not required anymore.
 *
 * We will also generate a Transport Order Identifier (tos_id), that could be used to book a delivery.
 */
export const completeSession = (payload: IngridCompleteSessionRequest) =>
  ingridApiCall<IngridCompleteSessionResponse>(ingridEndpointDictionary.complete.url, {
    method: ingridEndpointDictionary.complete.method,
    headers: headersJson,
    body: JSON.stringify(payload)
  })

/**
 * At any point of a session lifetime, session can be fetched using session.get call.
 * The only argument to this call is the checkout_session_id of the session.
 *
 * Delivery promises presented in category options have a certain time of freshness, during which they are valid.
 * After a certain time has passed,
 * the validity of delivery promise expires and the delivery promise has to be generated again.
 *
 * This call is idempotent.
 * If you want to also regenerate expired delivery promises for current time use session.pull instead.
 */
export const getSession = (sessionId: string) =>
  ingridApiCall<IngridGetSessionResponse>(ingridEndpointDictionary.get.url(sessionId), {
    method: ingridEndpointDictionary.get.method
  })

/**
 * At any point of a session lifetime, session can be fetched using session.pull call.
 * The only argument to this call is ID of the session.
 *
 * This call is not idempotent. Ingrid will make sure, that shipping options presented to the customer
 * are still valid and will try to refresh them in this case. If you want to just have the snapshot of
 * a session use session.get instead.
 */
export const pullSession = (sessionId: string) =>
  ingridApiCall<IngridGetSessionResponse>(ingridEndpointDictionary.pull.url(sessionId), {
    method: ingridEndpointDictionary.pull.method
  })
