import { type CreateUpdateOrderDTO, CustomerType, type OrderDTO, type SveaOrder } from 'ecosystem'
import {
  convertMinorUnitToValue,
  getTax,
  getVatRateByCountryCode,
  roundNumber,
  sumArrayOfNumbers
} from 'shared-utils'
import { createError } from '../../../../utils/fetching'
import { type CompleteStorefrontOrderProps } from './types'
import { generateBasePayload, generatePayloadFromIngrid } from './utils'

const generateBasePayloadFromSvea = (
  oldOrder: OrderDTO,
  sveaOrder: SveaOrder
): CreateUpdateOrderDTO => {
  const {
    BillingAddress: billingAddress,
    ShippingAddress: shippingAddress,
    PhoneNumber: mobilePhone,
    EmailAddress: email
  } = sveaOrder
  const sveaItems = sveaOrder.Cart.Items

  const {
    FirstName: firstName = '',
    LastName: lastName = '',
    FullName: fullName = ''
  } = billingAddress ?? {}

  const orderDiscount = sveaItems.reduce((sum, item) => {
    return item.DiscountAmount ? sum + item.DiscountAmount : sum
  }, 0)

  const shippingCost = sveaItems.reduce((sum, item) => {
    return item.ArticleNumber === 'delivery' ? sum + item.UnitPrice : sum
  }, 0)

  const orderAmount = convertMinorUnitToValue(
    sumArrayOfNumbers(
      sveaItems.map((item) => item.UnitPrice * (item.Quantity / 100) - (item.DiscountAmount ?? 0))
    )
  )

  return {
    ...generateBasePayload(oldOrder),
    billingAddress: {
      address1: billingAddress?.StreetAddress ?? '',
      address2: '',
      country: billingAddress?.CountryCode ?? '',
      mobilePhone,
      postalCode: billingAddress?.PostalCode ?? '',
      postalRegion: billingAddress?.City ?? '',
      attention: fullName,
      reference: ''
    },
    shippingAddress: {
      address1: shippingAddress?.StreetAddress ?? '',
      address2: '',
      country: shippingAddress?.CountryCode ?? '',
      mobilePhone,
      postalCode: shippingAddress?.PostalCode ?? '',
      postalRegion: shippingAddress?.City ?? '',
      attention: shippingAddress?.FullName ?? '',
      reference: ''
    },
    currency: sveaOrder.Currency,
    customer: {
      email,
      firstName,
      lastName,
      type: CustomerType.CONSUMER
    },
    orderAmount,
    orderTaxAmount: roundNumber(
      getTax(orderAmount, getVatRateByCountryCode(sveaOrder?.CountryCode))
    ),
    orderDiscount: roundNumber(convertMinorUnitToValue(orderDiscount)),
    shippingCost: convertMinorUnitToValue(shippingCost),

    paymentId: String(sveaOrder.OrderId),
    paymentProvider: 'SVEA'
  }
}

export const sveaPaymentProvider = ({
  order: oldOrder,
  paymentProviderInfo,
  shippingProviderInfo
}: CompleteStorefrontOrderProps): CreateUpdateOrderDTO => {
  if (!paymentProviderInfo.providerResponse?.sveaResponse) {
    throw createError('Not found SVEA order')
  }
  const shippingProviderName = shippingProviderInfo?.providerName ?? 'DEFAULT'

  let payload = generateBasePayloadFromSvea(
    oldOrder,
    paymentProviderInfo.providerResponse?.sveaResponse
  )

  switch (shippingProviderName) {
    case 'DEFAULT':
      break
    case 'INGRID':
      payload = generatePayloadFromIngrid(payload, shippingProviderInfo?.ingridResponse)
  }

  return payload
}
