import type { CreateUpdateOrderDTO, OrderDTO } from 'ecosystem'
import {
  createEndpointError,
  createError,
  fetchPropsFactory,
  storefrontApiCall
} from '../../../../utils/fetching'
import { ORDERS_ENDPOINT } from '../../constants'
import { type CompleteStorefrontOrderProps } from './types'
import { klarnaPaymentProvider } from './klarnaPaymentProvider'
import { nexiPaymentProvider } from './nexiPaymentProvider'
import { sveaPaymentProvider } from './sveaPaymentProvider'
import { generateBasePayload } from './utils'

export async function completeStorefrontOrder({
  order: oldOrder,
  paymentProviderInfo,
  shippingProviderInfo
}: CompleteStorefrontOrderProps) {
  let payload: CreateUpdateOrderDTO = generateBasePayload(oldOrder)

  switch (paymentProviderInfo?.providerName ?? 'KLARNA') {
    case 'KLARNA':
      payload = klarnaPaymentProvider({
        order: oldOrder,
        paymentProviderInfo,
        shippingProviderInfo
      })
      break

    case 'NEXI':
      payload = nexiPaymentProvider({
        order: oldOrder,
        paymentProviderInfo,
        shippingProviderInfo
      })
      break

    case 'SVEA':
      payload = sveaPaymentProvider({
        order: oldOrder,
        paymentProviderInfo,
        shippingProviderInfo
      })
      break

    case 'LEDYER': {
      throw createError('Not implemented completeStorefrontOrder for LEDYER case')
    }
  }

  const props = fetchPropsFactory({
    props: {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(payload)
    }
  })

  const { data: order, error } = await storefrontApiCall<OrderDTO>(ORDERS_ENDPOINT, props)

  if (error || !order) {
    return createEndpointError('complete storefront order')
  }

  return order
}
