import { type SveaCreateOrderModel, type SveaOrder, type SveaUpdateOrderModel } from 'ecosystem'
import type { ApiCallResponse } from '../../fetching'
import { SVEA_API_CHECKOUT_URL } from '../constants'
import { createSveaHeaders } from '../utils'

const CHECKOUT_URL = `${SVEA_API_CHECKOUT_URL}/api/orders`

const headersJson = {
  'Content-Type': 'application/json'
}

const sveaApiCall = async <Data>(
  url: string,
  { headers, ...props }: RequestInit
): Promise<ApiCallResponse<Data>> => {
  if (url.includes('undefined')) {
    throw new Error('Svea environment variables missing')
  }

  try {
    const response = await fetch(url, {
      headers: {
        ...createSveaHeaders(props.body as string),
        ...headers
      },
      ...props
    })

    /** @see https://checkoutapistage.svea.com/docs/#/order-api */
    const knownErrors = {
      400: 'The input data was invalid.',
      401: 'The request did not contain correct authorization.',
      403: 'The request did not contain correct authorization.',
      404: 'No order with the requested ID was found.'
    }

    if (knownErrors[response.status as keyof typeof knownErrors]) {
      return {
        data: null,
        error: knownErrors[response.status as keyof typeof knownErrors]
      }
    }

    if (!(response.status >= 200 && response.status < 300)) {
      return {
        data: null,
        error: 'Unknown error occurred.'
      }
    }

    try {
      const responseData = await response.json()

      return {
        data: responseData,
        error: null
      }
    } catch (e) {
      return {
        data: null,
        error: `PARSING RESPONSE ERROR: ${e as string}`
      }
    }
  } catch (error) {
    return {
      data: null,
      error: `API ERROR: ${error as string}`
    }
  }
}

/**
 * Use this request to create a Checkout order.
 * The response contains the necessary data to embed the Checkout on your site.
 * @see https://checkoutapistage.svea.com/docs/#/order-api?id=create-order
 */
export const createOrder = (payload: SveaCreateOrderModel) =>
  sveaApiCall<SveaOrder>(CHECKOUT_URL, {
    method: 'POST',
    headers: headersJson,
    body: JSON.stringify(payload)
  })

/**
 * Use this request to retrieve an existing Checkout order
 * @see https://checkoutapistage.svea.com/docs/#/order-api?id=get-order
 */
export const retrieveOrder = (orderId: string | number) =>
  sveaApiCall<SveaOrder>(`${CHECKOUT_URL}/${orderId}`, {
    method: 'GET'
  })

/**
 * Use this request to update an existing Checkout order.
 * @remarks
 * Only cart and merchantData can be updated on an existing order.
 * If you need to update other fields, simply create a new Checkout order.
 * @see https://checkoutapistage.svea.com/docs/#/order-api?id=update-order
 */
export const updateOrder = (orderId: string | number, payload: SveaUpdateOrderModel) =>
  sveaApiCall<SveaOrder>(`${CHECKOUT_URL}/${orderId}`, {
    method: 'PUT',
    headers: headersJson,
    body: JSON.stringify(payload)
  })
