import type { CustomerDTO, CustomerUpdateDTO, OrderDTO, PaginatedResponse } from 'ecosystem'
import type { UrlConfig } from '../utils/fetching'
import {
  createError,
  fetchPropsFactory,
  storefrontApiCall,
  urlWithQueryParamsFactory
} from '../utils/fetching'
import { BASE_STOREFRONT_ENDPOINT } from './storeEndpoint'

const customerEndpoint = `${BASE_STOREFRONT_ENDPOINT}/customer`

export async function findById(customerId: string): Promise<CustomerDTO> {
  const props = fetchPropsFactory({
    props: {
      method: 'GET'
    }
  })
  const url = `${customerEndpoint}/${customerId}`
  const { data: customer, error } = await storefrontApiCall<CustomerDTO>(url, props)

  if (!customer) {
    throw createError(error)
  }

  return customer
}

export async function update(
  customerId: string,
  params: Partial<CustomerUpdateDTO>
): Promise<CustomerDTO> {
  const props = fetchPropsFactory({
    props: {
      method: 'PUT',
      body: JSON.stringify(params)
    }
  })
  const url = `${customerEndpoint}/${customerId}`
  const { data: customer, error } = await storefrontApiCall<CustomerDTO>(url, props)

  if (!customer) {
    throw createError(error)
  }

  return customer
}

export async function getCustomerOrders(
  customerId: string,
  config: UrlConfig<void>
): Promise<PaginatedResponse<OrderDTO[]>> {
  const props = fetchPropsFactory({
    props: {
      method: 'GET'
    }
  })
  const url = urlWithQueryParamsFactory(`${customerEndpoint}/${customerId}/orders`, config)
  const { data: pagRes, error } = await storefrontApiCall<PaginatedResponse<OrderDTO[]>>(url, props)

  if (!pagRes) {
    throw createError(error)
  }

  return pagRes
}

export async function getCustomerOrderItems(
  customerId: string,
  orderId: string
): Promise<OrderDTO> {
  const props = fetchPropsFactory({
    props: {
      method: 'GET'
    }
  })
  const url = urlWithQueryParamsFactory(`${customerEndpoint}/${customerId}/orders/${orderId}/items`)
  const { data: order, error } = await storefrontApiCall<OrderDTO>(url, props)

  if (!order) {
    throw createError(error)
  }

  return order
}
