import {
  createSession,
  updateSession,
  completeSession,
  getSession,
  pullSession
} from './ingridCheckoutEndpoints'

export const ingridCheckoutApi = {
  createSession,
  updateSession,
  completeSession,
  getSession,
  pullSession
}
