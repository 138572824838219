import type { ParsedUrlQuery } from 'node:querystring'
import type { FilterDTO, FilterOptionDTO, StoreProductFilterDTO } from 'ecosystem'
import { FilterType } from 'ecosystem'
import type { ProductQueryParams } from '../storefront-api/productsEndpoint'
import type { UrlConfig } from './fetching'

export const getFilterType = (filter: Partial<FilterDTO | FilterOptionDTO>): FilterType =>
  filter.textValues?.length ? FilterType.TEXT : FilterType.NUMBER

export type SharedFilters = Partial<{
  minPrice: number
  maxPrice: number
  filters: Partial<FilterDTO>[]
}>

export type GetInitialProductsType = 'category' | 'brand' | 'search' | 'tag'

export const getFiltersByType = (
  id: string,
  type: GetInitialProductsType,
  sharedFiltersProps?: SharedFilters
) => {
  const dictionary: Record<GetInitialProductsType, any> = {
    category: {
      categoryIds: [id],
      ...sharedFiltersProps
    },
    brand: {
      brandIds: [id],
      ...sharedFiltersProps
    },
    tag: {
      tagIds: [id],
      ...sharedFiltersProps
    },
    search: {
      search: id,
      ...sharedFiltersProps
    }
  }

  return dictionary[type] ?? {}
}

export const filterValuesFactory = (queryParams: ParsedUrlQuery, key: string) => {
  return (queryParams[key] as string).split(',')
}

export interface GetProductEndpointPayload {
  filter: Partial<StoreProductFilterDTO>
  config?: UrlConfig<ProductQueryParams>
}

export const searchQueryParamsFactory = (params: GetProductEndpointPayload) => {
  const priceFilters = {
    minPrice: params.filter.minPrice?.toString(),
    maxPrice: params.filter.maxPrice?.toString()
  }
  const { queryParams } = params.config || {}
  const { brandIds } = params.filter
  const sortFilters = {
    ...(Boolean(queryParams?.['sort.by']) && {
      sortBy: queryParams['sort.by']
    }),
    ...(Boolean(queryParams?.['sort.order']) && {
      sortOrder: queryParams['sort.order']
    })
  }
  const filterQueryParams = params.filter.filters?.reduce((obj, item) => {
    let text
    let number
    const filterType = getFilterType(item)

    if (!item.name) {
      return {}
    }

    if (filterType === FilterType.TEXT) {
      text = Object.assign(obj, { [item.name.toLowerCase()]: item.textValues?.toString() })
    }

    if (filterType === FilterType.NUMBER) {
      number = Object.assign(obj, { [item.name.toLowerCase()]: item.numberValues?.toString() })
    }

    return { ...text, ...number }
  }, {})

  // const priceParsedParams = '?' + new URLSearchParams( priceFilters ).toString()
  // const filterParsedParams = '&' + new URLSearchParams( filterQueryParams ).toString()
  return {
    ...priceFilters,
    ...sortFilters,
    ...(brandIds?.length && { brandIds }),
    ...filterQueryParams
  }
}
