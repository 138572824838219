import type { DiscountDTO, KlarnaOrderLine, MinorUnit, StorefrontOrderLine } from 'ecosystem'
import { createMinorUnit, getTax, getVatRateByCountryCode, sumArrayOfNumbers } from 'shared-utils'
import type { ApiCallResponse } from './fetching'
import { apiCall } from './fetching'
import { orderLineValueCalculator } from './payment'

export const klarnaOrderLineFactory = (
  items: StorefrontOrderLine[],
  cartDiscountCode: DiscountDTO | null,
  countryCode?: string
) => {
  const taxRate = createMinorUnit(getVatRateByCountryCode(countryCode))
  const productOrderLines = items.map((i) =>
    mapStorefrontProductsIntoKlarna(i, cartDiscountCode, taxRate)
  )
  const serviceOrderLines = servicesOrderLineFactory(items, taxRate)
  const orderLines = [...productOrderLines, ...serviceOrderLines]
  const orderAmount = sumArrayOfNumbers(
    orderLines.map((item: KlarnaOrderLine) => item.total_amount)
  )
  return { orderLines, orderAmount, productOrderLines, taxRate }
}

export const servicesOrderLineFactory = (
  items: StorefrontOrderLine[],
  taxRate: MinorUnit
): KlarnaOrderLine[] => {
  return items
    .map((item) =>
      item.services.map((service) => {
        const quantity = service.quantity
        let unitPrice = service.service.price * 100
        const discountFactor = service.service.discountPrice * 100
        let totalDiscountAmount = 0
        let totalAmount = 0

        /*
          Basic formula for calculating price of service:
          service.price + service.discountPrice * (quantity - 1)
         */
        if (quantity > 0) {
          totalAmount = unitPrice + (quantity - 1) * discountFactor

          if (!unitPrice && discountFactor) {
            unitPrice = discountFactor
          }

          totalDiscountAmount = unitPrice * quantity - totalAmount
        }

        return {
          type: 'surcharge' as const,
          name: service.service.name,
          tax_rate: taxRate,
          quantity,
          total_amount: totalAmount,
          unit_price: unitPrice,
          total_tax_amount: getTax(totalAmount, taxRate, true),
          total_discount_amount: totalDiscountAmount,
          merchant_data: service.service.id
        }
      })
    )
    .flat()
}

export const mapStorefrontProductsIntoKlarna = (
  item: StorefrontOrderLine,
  cartDiscountCode: DiscountDTO | null,
  taxRate: MinorUnit
): KlarnaOrderLine => {
  const { quantity, totalDiscountAmount, totalAmount, unitPrice } = orderLineValueCalculator(
    item,
    cartDiscountCode
  )
  const totalTaxAmount = getTax(totalAmount, taxRate, true)

  return {
    type: 'physical',
    name: item.product.name,
    tax_rate: taxRate,
    quantity,
    total_amount: totalAmount,
    unit_price: unitPrice,
    total_tax_amount: totalTaxAmount,
    total_discount_amount: totalDiscountAmount,
    merchant_data: item.product.id
  }
}

interface KlarnaEndpointOptions<T> {
  orderId?: string
  payload?: T
}

type KlarnaApiCallResponse<T> = T | { error_code: string }

export const klarnaApiCall = async <T, K>(
  type: 'create' | 'get' | 'update' | 'acknowledge' | 'cancel',
  options: KlarnaEndpointOptions<K>
): Promise<ApiCallResponse<T>> => {
  const { orderId, payload } = options

  const klarnaEndpointDictionary = {
    create: {
      method: 'POST',
      url: `${process.env.KLARNA_URL}/checkout/v3/orders`
    },

    update: {
      method: 'POST',
      url: `${process.env.KLARNA_URL}/checkout/v3/orders/${orderId}`
    },

    get: {
      method: 'GET',
      url: `${process.env.KLARNA_URL}/checkout/v3/orders/${orderId}`
    },

    acknowledge: {
      method: 'POST',
      url: `${process.env.KLARNA_URL}/ordermanagement/v1/orders/${orderId}/acknowledge`
    },

    cancel: {
      method: 'POST',
      url: `${process.env.KLARNA_URL}/ordermanagement/v1/orders/${orderId}/cancel`
    }
  }

  const credentials = Buffer.from(
    `${process.env.KLARNA_USERNAME}:${process.env.KLARNA_PASSWORD}`
  ).toString('base64')
  const headers = { Authorization: `Basic ${credentials}`, 'Content-Type': 'application/json' }
  const props = {
    headers,
    method: klarnaEndpointDictionary[type].method,
    // optional body if payload
    ...(payload ? { body: JSON.stringify(payload) } : {})
  }

  const { error, data } = await apiCall<KlarnaApiCallResponse<T>>(
    klarnaEndpointDictionary[type].url,
    props
  )

  if (error || !data) {
    return {
      data: null,
      error
    }
  }

  // @ts-expect-error -- FIX
  if ('error_code' in data) {
    return {
      data: null,
      error: data.error_code
    }
  }

  return {
    data,
    error: null
  }
}
