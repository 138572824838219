import type { UpdateOrderStatusDTO } from 'ecosystem'
import {
  fetchPropsFactory,
  storefrontApiCall,
  urlWithQueryParamsFactory
} from '../../utils/fetching'
import { ORDERS_ENDPOINT } from './constants'
import type { GetStorefrontOrderParams } from './getStorefrontOrderByOrderNumber'

export interface UpdateStorefrontOrderStatusParams {
  orderId: string
  payload: UpdateOrderStatusDTO
}

export async function updateStorefrontOrderStatus(params: UpdateStorefrontOrderStatusParams) {
  const url = urlWithQueryParamsFactory<GetStorefrontOrderParams>(
    `${ORDERS_ENDPOINT}/${params.orderId}/status`
  )
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(params.payload)
    }
  })

  return storefrontApiCall<null>(url, props)
}
