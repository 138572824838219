import type { OldStoreProductFilterDTO, StoreProductFilterDTO } from 'ecosystem'
import lodashSortBy from 'lodash/sortBy'
import { createEndpointError, fetchPropsFactory, storefrontApiCall } from '../../utils/fetching'
import { storeEndpoint } from './constants'

export async function getFilterOptions(filter: Partial<StoreProductFilterDTO>) {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(filter)
    }
  })
  const { data, error } = await storefrontApiCall<OldStoreProductFilterDTO>(
    `${storeEndpoint}/filters`,
    props
  )
  if (error) {
    return createEndpointError('getFilterOptions', error)
  }

  if (data) {
    sortFilters(data)
  }

  return data
}

const sortFilters = (data: OldStoreProductFilterDTO | null) => {
  if (data?.brands) {
    data.brands = lodashSortBy(data?.brands, 'name')
  }

  if (data?.options) {
    data.options.forEach((option) => {
      if (option.numberValues) {
        option.numberValues.sort((a, b) => a - b)
      }

      if (option.textValues) {
        interface NumberValue {
          value: string
          numberValue: number
        }
        const newTextValues = option.textValues.reduce<{
          stringValues: string[]
          numberValues: NumberValue[]
        }>(
          (res, item) => {
            if (Number.isNaN(parseInt(item))) {
              res.stringValues = [...res.stringValues, item]
            } else {
              res.numberValues = [...res.numberValues, { value: item, numberValue: parseInt(item) }]
            }

            return res
          },
          { stringValues: [], numberValues: [] }
        )
        newTextValues.numberValues.sort((a, b) => a.numberValue - b.numberValue)

        option.textValues = [
          ...newTextValues.numberValues.map((item) => item.value),
          ...newTextValues.stringValues.sort()
        ]
      }
    })
  }
}
