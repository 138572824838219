import type { DiscountDTO, MinorUnit, StorefrontOrderLine, SveaOrderRow } from 'ecosystem'
import { createMinorUnit, getVatRateByCountryCode, sumArrayOfNumbers } from 'shared-utils'
import { orderLineValueCalculator } from '../../payment'

/**
 * Svea order item name has a limit of 40 symbols
 */
const createOrderItemName = (name: string): SveaOrderRow['Name'] => {
  return name.substring(0, 40)
}

/**
 * Svea order item unit has a limit of 4 symbols
 */
const createOrderItemUnit = (name: string): SveaOrderRow['Unit'] => {
  return name.substring(0, 4)
}

const mapOrderLinesToSveaOrderItems = (
  items: StorefrontOrderLine[],
  cartDiscountCode: DiscountDTO | null,
  taxRate: MinorUnit
): SveaOrderRow[] => {
  return items.map((item) => {
    const { product, quantity } = item
    const { unitPrice, totalDiscountAmount } = orderLineValueCalculator(item, cartDiscountCode)
    return {
      ArticleNumber: product.id,
      Name: createOrderItemName(product.name),
      Quantity: createMinorUnit(quantity),
      Unit: createOrderItemUnit('pcs'),
      UnitPrice: unitPrice,
      VatPercent: taxRate,
      DiscountAmount: totalDiscountAmount,
      RowType: 'Row'
    } satisfies SveaOrderRow
  })
}

const servicesOrderLineFactory = (
  items: StorefrontOrderLine[],
  taxRate: MinorUnit
): SveaOrderRow[] => {
  return items
    .map((item) =>
      item.services.map((service) => {
        const quantity = service.quantity
        let unitPrice = createMinorUnit(service.service.price)
        const discountFactor = createMinorUnit(service.service.discountPrice)
        let totalDiscountAmount = 0
        let totalAmount = 0

        /*
         Basic formula for calculating price of service:
         service.price + service.discountPrice * (quantity - 1)
         */
        if (quantity > 0) {
          totalAmount = unitPrice + (quantity - 1) * discountFactor

          if (!unitPrice && discountFactor) {
            unitPrice = discountFactor
          }

          totalDiscountAmount = unitPrice * quantity - totalAmount
        }

        return {
          ArticleNumber: service.service.id,
          Name: createOrderItemName(service.service.name),
          Quantity: createMinorUnit(quantity),
          Unit: createOrderItemUnit('service'),
          UnitPrice: unitPrice,
          VatPercent: taxRate,
          DiscountAmount: totalDiscountAmount,
          RowType: 'Row'
        } satisfies SveaOrderRow
      })
    )
    .flat()
}

export const sveaOrderLineFactory = (
  items: StorefrontOrderLine[],
  cartDiscountCode: DiscountDTO | null,
  countryCode?: string
) => {
  const taxRate = createMinorUnit(getVatRateByCountryCode(countryCode))
  const productOrderLines = mapOrderLinesToSveaOrderItems(items, cartDiscountCode, taxRate)
  const serviceOrderLines = servicesOrderLineFactory(items, taxRate)
  const orderLines = [...productOrderLines, ...serviceOrderLines]
  const orderAmount = sumArrayOfNumbers(
    orderLines.map((item) => item.UnitPrice * (item.Quantity / 100) - (item.DiscountAmount ?? 0))
  )
  return { orderLines, productOrderLines, orderAmount, taxRate }
}
