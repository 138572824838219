import type { AssistantChatRequestDTO, AssistantChatResponseDTO } from 'ecosystem'
import { createError, fetchPropsFactory, storefrontApiCall } from '../utils/fetching'

const assistantEndpoint = `${process.env.NEXT_PUBLIC_API_HOST}/assistant`

export async function getAssistantChatResponse(
  payload: AssistantChatRequestDTO
): Promise<AssistantChatResponseDTO | null> {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(payload)
    }
  })
  const url = `${assistantEndpoint}/chat`

  const { data: assistantResponse, error } = await storefrontApiCall<AssistantChatResponseDTO>(
    url,
    props
  )

  if (error) {
    throw createError(error)
  }

  return assistantResponse
}
