import { type CreateUpdateOrderDTO, CustomerType, type KlarnaOrder, type OrderDTO } from 'ecosystem'
import { convertMinorUnitToValue, sumPropValues } from 'shared-utils'
import { createError } from '../../../../utils/fetching'
import { type CompleteStorefrontOrderProps } from './types'
import { generateBasePayload, generatePayloadFromIngrid } from './utils'

const generateBasePayloadFromKlarna = (
  oldOrder: OrderDTO,
  klarnaOrderDetail: KlarnaOrder
): CreateUpdateOrderDTO => {
  return {
    ...generateBasePayload(oldOrder),
    billingAddress: {
      address1: klarnaOrderDetail.billing_address.street_address,
      address2: '',
      country: klarnaOrderDetail.billing_address.country,
      mobilePhone: klarnaOrderDetail.billing_address.phone,
      postalCode: klarnaOrderDetail.billing_address.postal_code,
      postalRegion: klarnaOrderDetail.billing_address.city,
      attention: `${klarnaOrderDetail.billing_address.given_name} ${klarnaOrderDetail.billing_address.family_name}`,
      reference: ''
    },
    customer: {
      email: klarnaOrderDetail.billing_address.email,
      firstName: klarnaOrderDetail.billing_address.given_name,
      lastName: klarnaOrderDetail.billing_address.family_name,
      type: CustomerType.CONSUMER
    },
    orderAmount: convertMinorUnitToValue(klarnaOrderDetail.order_amount),
    orderTaxAmount: convertMinorUnitToValue(klarnaOrderDetail.order_tax_amount),
    orderDiscount: convertMinorUnitToValue(
      sumPropValues(klarnaOrderDetail.order_lines, 'total_discount_amount')
    ),
    ...(klarnaOrderDetail.selected_shipping_option
      ? {
          shippingCost: convertMinorUnitToValue(klarnaOrderDetail.selected_shipping_option.price)
        }
      : {}),
    paymentId: klarnaOrderDetail.order_id,
    paymentProvider: 'KLARNA',
    shippingAddress: {
      address1: klarnaOrderDetail.shipping_address.street_address,
      address2: '',
      country: klarnaOrderDetail.shipping_address.country,
      mobilePhone: klarnaOrderDetail.shipping_address.phone,
      postalCode: klarnaOrderDetail.shipping_address.postal_code,
      postalRegion: klarnaOrderDetail.shipping_address.city,
      attention: `${klarnaOrderDetail.shipping_address.given_name} ${klarnaOrderDetail.shipping_address.family_name}`,
      reference: ''
    }
  }
}

export const klarnaPaymentProvider = ({
  order: oldOrder,
  paymentProviderInfo,
  shippingProviderInfo
}: CompleteStorefrontOrderProps): CreateUpdateOrderDTO => {
  if (!paymentProviderInfo.providerResponse?.klarnaResponse) {
    throw createError('Not found KLARNA order')
  }
  const shippingProviderName = shippingProviderInfo?.providerName ?? 'DEFAULT'

  let payload = generateBasePayloadFromKlarna(
    oldOrder,
    paymentProviderInfo.providerResponse?.klarnaResponse
  )

  switch (shippingProviderName) {
    case 'DEFAULT':
      break
    case 'INGRID':
      payload = generatePayloadFromIngrid(payload, shippingProviderInfo?.ingridResponse)
  }

  return payload
}
