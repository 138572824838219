import {
  type AssistantTextToSpeechRequestDTO,
  type AssistantV2ChatRequestDTO,
  type AssistantV2ChatResponseDTO,
  type AssistantV2ThreadRequestDTO,
  type AssistantV2ThreadResponseDTO
} from 'ecosystem'
import { constructURLWithParams } from 'shared-utils'
import { createError, fetchPropsFactory, storefrontApiCall } from '../utils/fetching'

const assistantEndpoint = `${process.env.NEXT_PUBLIC_API_HOST}/assistant`

export async function getAssistantV2ChatResponse({
  sessionId,
  ...payload
}: AssistantV2ChatRequestDTO & { sessionId: string }): Promise<AssistantV2ChatResponseDTO | null> {
  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(payload)
    }
  })
  const url = constructURLWithParams(`${assistantEndpoint}/v2/chat`, {
    sessionId
  })

  const { data: assistantResponse, error } = await storefrontApiCall<AssistantV2ChatResponseDTO>(
    url,
    props
  )

  if (error) {
    throw createError(error)
  }

  return assistantResponse
}

export async function getAssistantV2Thread(
  payload: AssistantV2ThreadRequestDTO
): Promise<AssistantV2ThreadResponseDTO | null> {
  const props = fetchPropsFactory({
    props: {
      method: 'GET'
    }
  })
  const url = `${assistantEndpoint}/thread${payload.customerId ? `?customerId=${payload.customerId}` : ''}`

  const { data: assistantResponse, error } = await storefrontApiCall<AssistantV2ThreadResponseDTO>(
    url,
    props
  )

  if (error) {
    throw createError(error)
  }

  return assistantResponse
}

export async function textToSpeech(
  payload: AssistantTextToSpeechRequestDTO
): Promise<ReadableStream | null> {
  const url = `${assistantEndpoint}/text-to-speech`

  const props = fetchPropsFactory({
    props: {
      method: 'POST',
      body: JSON.stringify(payload)
    }
  })

  const res = await fetch(url, props)

  if (!res.ok) {
    throw createError(res.statusText)
  }

  const responseBody = res.body

  if (responseBody instanceof ReadableStream) {
    return responseBody
  }
  return null
}
